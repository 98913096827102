import styled from "styled-components";

import { CallType } from "../contracts/views";
import Call from "./Call";
import { Header4 } from "../styles/Headers";

const StyledCallSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10rem;
`;

const Headers = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 1.7rem;

  > div:nth-child(1) {
    flex: 0.3;
  }
  > div:nth-child(2) {
    flex: 1.1;
  }
  > div:nth-child(5) {
    flex: 1.2;
  }
`;

const Header = styled.div`
  flex: 1;
  text-align: left;
  font-weight: 700;
  line-height: 2.4rem;
  letter-spacing: 0.15px;
  opacity: 0.6;
  font-size: 1.4rem;
`;

const ChevronHeader = styled.div`
  width: 2.4rem;
`;

interface Props {
  calls: CallType[];
}

const CallSection = ({ calls }: Props) => {
  if (calls.length === 0) return null;

  const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";

  return (
    <StyledCallSection>
      <Header4>{`${calls[0].status} Calls`}</Header4>
      <Headers>
        <Header>Id</Header>
        <Header>Prepared on</Header>
        <Header>Target</Header>
        <Header>Contract</Header>
        <Header>Function</Header>
        <ChevronHeader />
      </Headers>
      {calls
        .filter((call) => call.target !== ZERO_ADDRESS)
        .sort((a: CallType, b: CallType) => a.id - b.id)
        .map((call: CallType) => (
          <Call key={call.id} call={call} />
        ))}
    </StyledCallSection>
  );
};

export default CallSection;
