import { isAddress } from "@ethersproject/address";
import contracts from "@merofinance/protocol/config/deployments/map.json";
import { useEthers } from "@usedapp/core";
import { utils } from "ethers";
import { useState } from "react";
import styled from "styled-components";
import useContractAbi from "../../app/hooks/use-contract-abi";
import useContractFunctions, {
  FunctionType,
} from "../../app/hooks/use-contract-functions";
import BasicInput from "../../components/BasicInput";
import Button from "../../components/Button";
import Dropdown, { DropdownOptionType } from "../../components/Dropdown";
import Popup from "../../components/Popup";

const StyledCreatePage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  font-size: 2.3rem;
  padding: 3rem;
`;

const Content = styled.div`
  width: 100%;
  max-width: 50rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    width: 100%;
    margin: 2rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

function getContracts(chainId: number): Record<string, string[]> {
  switch (chainId) {
    case 5:
      return contracts["42"];
    case 42:
      return contracts["42"];
    case 1337:
      return contracts["1337"];
    default:
      return contracts["1"];
  }
}

const CreatePage = () => {
  const [contract, setContract] = useState("");
  const [func, setFunc] = useState<FunctionType | null>(null);
  const [values, setValues] = useState<Record<number, string>>([]);
  const [finalData, setFinalData] = useState("");
  const functions = useContractFunctions(contract);
  const abi = useContractAbi(contract);
  const { chainId } = useEthers();

  const allContracts = getContracts(chainId || 1);
  const contractOptions = Object.entries(allContracts)
    .flatMap(([name, addresses]) =>
      addresses.map((a) => ({
        label: `${name} (${a.slice(0, 8)}...${a.slice(-6)})`,
        value: a,
      }))
    )
    .sort((a, b) => a.label.localeCompare(b.label));

  const formatValue = (type: string, value: string): any => {
    if (type === "address") {
      if (!isAddress(value)) throw Error("Invalid address");
      return value;
    }

    if (type === "bool") {
      if (value.toLowerCase() === "true") return true;
      if (value.toLowerCase() === "false") return false;
      throw Error("Invalid boolean");
    }

    if (type.includes("[]")) {
      return value
        .replace(" ", "")
        .replace("[", "")
        .replace("]", "")
        .split(",")
        .map((v: string) => formatValue(type.slice(0, -2), v));
    }

    return value;
  };

  const getValues = () => {
    if (!func) return [];
    if (func.inputs.length === 0) return [];
    const outputValues: any[] = [];
    for (let i = 0; i < func.inputs.length; i++) {
      const { name, type } = func.inputs[i];
      outputValues.push(formatValue(type, values[i]));
    }
    return outputValues;
  };

  const submit = () => {
    if (!func) return;
    const iface = new utils.Interface(abi);
    const data = iface.encodeFunctionData(func.name, getValues());
    setFinalData(data);
    // USED FOR TESTING THE OUTPUT IS CORRECT
    // console.log(data);
    // const decodedData = decodedatainputsfromtypes(
    //   func.inputs.map(({ type }) => type),
    //   data
    // );
    // console.log(decodedData)
  };

  return (
    <StyledCreatePage>
      <Content>
        <BasicInput
          value={contract}
          setValue={(value: string) => {
            setContract(value);
          }}
          placeholder="Contract address"
          options={contractOptions}
          error={contract && !isAddress(contract) ? "Invalid address" : ""}
        />
        {functions.length > 0 && (
          <Dropdown
            label={func ? func.name : "Select function"}
            options={functions.map((func: FunctionType): DropdownOptionType => {
              return {
                label: func.name,
                action: () => setFunc(func),
              };
            })}
          />
        )}
        {func &&
          func.inputs.length > 0 &&
          func.inputs.map((input, index) => (
            <BasicInput
              key={index}
              type={
                input.type.includes("[]")
                  ? "text"
                  : input.type.includes("int")
                  ? "number"
                  : "text"
              }
              placeholder={values[index] || `${input.name} (${input.type})`}
              setValue={(value: string) =>
                setValues({ ...values, [index]: value })
              }
              value={values[index]}
            />
          ))}
        {func && !func.inputs.some((input, index) => !values[index]) && (
          <Button primary wide medium click={submit}>
            Generate Data
          </Button>
        )}
      </Content>
      <Popup
        show={!!finalData}
        close={() => setFinalData("")}
        header="Call Data"
        body={finalData}
      >
        <Button
          primary
          wide
          medium
          click={() => {
            navigator.clipboard.writeText(finalData);
            setFinalData("");
          }}
        >
          Copy to clipboard
        </Button>
      </Popup>
    </StyledCreatePage>
  );
};

export default CreatePage;
