import styled from "styled-components";

const StyledBasicInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

interface InputProps {
  valid: boolean;
}

const InputBorder = styled.div`
  position: relative;
  width: 100%;
  padding: 1px;

  background: ${(props: InputProps) =>
    props.valid
      ? "linear-gradient(to right, #c532f9, #32b2e5)"
      : "linear-gradient(to right, var(--error), var(--error))"};

  height: 4.5rem;
  border-radius: 14px;
  @media (max-width: 600px) {
    height: 3.4rem;
    border-radius: 10px;
  }
`;

const Input = styled.input`
  width: 100%;
  background-color: var(--bg-light);
  height: 100%;
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.15px;
  font-weight: 400;

  color: ${(props: InputProps) =>
    props.valid ? "var(--main)" : "var(--error)"};

  font-size: 1.6rem;
  border-radius: 13px;
  @media (max-width: 600px) {
    font-size: 1.3rem;
    border-radius: 9px;
  }

  -moz-appearance: textfield;
  ::-webkit-outer-spin-button {
    display: none;
  }
  ::-webkit-inner-spin-button {
    display: none;
  }

  ::placeholder {
    color: rgba(255, 255, 255, 0.4);
  }
`;

const AutocompleteList = styled.ul`
  list-style: none;
  width: 100%;

  li {
    &:not(:first-child) {
      border-top: 1px solid var(--main);
    }
    cursor: pointer;
    line-height: 2.5em;
    font-size: 1.5rem;
    padding: 0 1em;
    background-color: var(--bg-light);
    &:hover {
      background-color: var(--bg-dark);
    }
  }
`;

const Error = styled.div`
  font-weight: 500;
  color: var(--error);
  margin-top: 0.6rem;
  margin-left: 1.3rem;

  font-size: 1.6rem;
  @media (max-width: 600px) {
    font-size: 1.2rem;
    margin-left: 0.9rem;
  }
`;

type Option = {
  label: string;
  value: string;
};

interface Props {
  value: string;
  setValue: (value: string) => void;
  placeholder: string;
  options?: Option[];
  error?: string;
  type?: string;
  id?: string;
}

const BasicInput = ({
  value,
  setValue,
  placeholder,
  type = "text",
  options = [],
  error,
  id,
}: Props): JSX.Element => {
  const validOptions = options.filter((o) =>
    o.label.toLowerCase().includes(value.toLowerCase())
  );
  const valid = validOptions.length > 0 || !error;

  return (
    <StyledBasicInput>
      <InputBorder valid={valid}>
        <Input
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              if (validOptions.length > 0) {
                setValue(validOptions[0].value);
              }
            }
          }}
          id={`${id}-input`}
          valid={valid}
          type={type}
          value={value}
          placeholder={placeholder}
          onChange={(e) => setValue(e.target.value)}
        />
      </InputBorder>
      {validOptions.length > 0 ? (
        <AutocompleteList>
          {validOptions.map((o) => (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
            <li key={o.value} onClick={() => setValue(o.value)}>
              {o.label}
            </li>
          ))}
        </AutocompleteList>
      ) : null}
      {!valid && <Error id={`${id}-error`}>{error}</Error>}
    </StyledBasicInput>
  );
};

export default BasicInput;
