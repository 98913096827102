import styled from "styled-components";
import { shortenAddress } from "@usedapp/core";
import dateformat from "dateformat";
import { utils } from "ethers";

import { CallStatus, CallType, useIsOwner } from "../contracts/views";
import Popup from "./Popup";
import InfoBlock from "./InfoBlock";
import ExternalLink from "./ExternalLink";
import useGlobals from "../app/hooks/use-globals";
import {
  decodeDataInputs,
  decodeSelectorContract,
  decodeSelectorFunction,
  decodeSelectorTypes,
} from "../lib/decoder";
import { useCancel, useExecute } from "../contracts/functions";
import Button from "./Button";
import CountdownButton from "./CountdownButton";

const StyledCallDetails = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  button {
    margin-top: 1.5rem;
  }
`;

interface Props {
  show: boolean;
  close: () => void;
  call: CallType;
}

const CallDetails = ({ call, show, close }: Props) => {
  const globals = useGlobals();
  const isOwner = useIsOwner();

  const params = decodeDataInputs(call.selector, call.data);

  const { executeState, execute } = useExecute();
  const { cancelState, cancel } = useCancel();

  const isBase32String = (value: string): boolean => {
    try {
      utils.parseBytes32String(value);
      return true;
    } catch (e) {
      return false;
    }
  };

  const formatParam = (param: any): string | JSX.Element => {
    if (typeof param === "string") {
      if (param.startsWith("0x") && isBase32String(param)) {
        return utils.parseBytes32String(param);
      }
      if (param.startsWith("0x") && param.length === 42)
        return (
          <ExternalLink large link={`${globals.ETHERSCAN_LINK}${param}`}>
            {shortenAddress(param)}
          </ExternalLink>
        );
      return param;
    }
    if (Array.isArray(param)) {
      return (
        <ul>
          {param.map((p, i) => (
            <li key={i}>{formatParam(p)}</li>
          ))}
        </ul>
      );
    }
    if (typeof param === "number") {
      return param.toString();
    }
    if (typeof param === "boolean") {
      return param ? "true" : "false";
    }
    if (typeof param === "object") {
      return param.toString();
    }
    return "";
  };

  return (
    <Popup header={`Call ${call.id} Details`} show={show} close={close}>
      <StyledCallDetails>
        <InfoBlock
          sections={[
            [
              {
                label: "Id",
                tooltip: "The unique identifier of the call",
                value: call.id.toString(),
              },
              {
                label: "Status",
                tooltip: "The status of the call",
                value: call.status,
              },
              {
                label: "Prepared on",
                tooltip: "The date and time the call was prepared",
                value: dateformat(
                  call.prepared,
                  "ddd, mmm dS, yyyy, h:MM:ss TT"
                ),
              },
              {
                label: "Contract",
                tooltip: "The name of the contract being called",
                value: decodeSelectorContract(call.selector),
              },
              {
                label: "Target",
                tooltip: "The address of the contract called",
                value: (
                  <ExternalLink
                    large
                    link={`${globals.ETHERSCAN_LINK}${call.target}`}
                  >
                    {shortenAddress(call.target)}
                  </ExternalLink>
                ),
              },
            ],
            [
              {
                label: "Function",
                tooltip: "The function that is being called",
                value: decodeSelectorFunction(call.selector),
              },
              ...params.map((param, index) => {
                return {
                  label: ` - ${decodeSelectorTypes(call.selector)[index]}:`,
                  value: formatParam(param),
                };
              }),
            ],
          ]}
        />
        <CountdownButton call={call} />
        {call.status === CallStatus.Ready && (
          <Button
            wide
            primary
            medium
            loading={
              executeState.status === "Mining" ||
              executeState.status === "PendingSignature"
            }
            background="var(--row-bg)"
            click={() => execute(call.id)}
          >
            Execute
          </Button>
        )}
        {(call.status === CallStatus.Pending ||
          call.status === CallStatus.Ready) &&
          isOwner && (
            <Button
              wide
              primary
              medium
              destructive
              loading={
                cancelState.status === "Mining" ||
                cancelState.status === "PendingSignature"
              }
              background="var(--row-bg)"
              click={() => cancel(call.id)}
            >
              Cancel
            </Button>
          )}
      </StyledCallDetails>
    </Popup>
  );
};

export default CallDetails;
