import dateFormat from "dateformat";
import { CallStatus, CallType, useDelay } from "../contracts/views";
import Button from "./Button";

interface Props {
  call: CallType;
}

const CountdownButton = ({ call }: Props) => {
  const delay = useDelay(call.id);
  const executableOn = new Date(call.prepared);
  executableOn.setSeconds(call.prepared.getSeconds() + delay);
  if (call.status !== CallStatus.Pending) return null;

  return (
    <Button wide primary medium disabled click={() => console.log("meow")}>
      {dateFormat(executableOn, "ddd, mmm d, yyyy h:MM TT")}
    </Button>
  );
};

export default CountdownButton;
