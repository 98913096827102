export const ETHERSCAN_API_KEY = "B47EZCYK8RDHQR2SZID9R3KA4C5DI1V4G8";

interface GlobalsType {
  GOVERNANCE_TIMELOCK: string;
  ETHERSCAN_LINK: string;
  GNOSIS_SAFE: string;
  GNOSIS_API_URL: string;
  MERO_MULTISIG: string;
}

export const ETH_GLOBALS: GlobalsType = {
  GOVERNANCE_TIMELOCK: "0x4586B68DBf365DbA951122a3922F7E9493b88e30",
  ETHERSCAN_LINK: "https://etherscan.io/address/",
  GNOSIS_SAFE: "0x8Ca8f797506BBA85AD418dD2eb190Da561D37641",
  GNOSIS_API_URL: "https://safe-transaction.mainnet.gnosis.io/api/v1/",
  MERO_MULTISIG: "0x8Ca8f797506BBA85AD418dD2eb190Da561D37641",
};

export const KOVAN_GLOBALS: GlobalsType = {
  GOVERNANCE_TIMELOCK: "0x4586B68DBf365DbA951122a3922F7E9493b88e30",
  ETHERSCAN_LINK: "https://kovan.etherscan.io/address/",
  GNOSIS_SAFE: "0x8Ca8f797506BBA85AD418dD2eb190Da561D37641",
  GNOSIS_API_URL: "https://safe-transaction.mainnet.gnosis.io/api/v1/",
  MERO_MULTISIG: "0x8Ca8f797506BBA85AD418dD2eb190Da561D37641",
};
