import styled from "styled-components";

import {
  useCancelledCalls,
  useExecutedCalls,
  usePendingCalls,
  useReadyCalls,
} from "../../contracts/views";
import CallSection from "../../components/CallSection";

const StyledHomePage = styled.div`
  width: 100%;
  max-width: 120rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 3rem;
`;

const HomePage = () => {
  const pendingCalls = usePendingCalls();
  const executedCalls = useExecutedCalls();
  const cancelledCalls = useCancelledCalls();
  const readyCalls = useReadyCalls();

  return (
    <StyledHomePage>
      <CallSection calls={readyCalls} />
      <CallSection calls={pendingCalls} />
      <CallSection calls={executedCalls} />
      <CallSection calls={cancelledCalls} />
    </StyledHomePage>
  );
};

export default HomePage;
