import abi from "./governance-timelock-abi.json";
import useGlobals from "../app/hooks/use-globals";
import useContract from "../app/hooks/use-contract";

export const useExecute = () => {
  const globals = useGlobals();

  const { state: executeState, send: execute } = useContract(
    globals.GOVERNANCE_TIMELOCK,
    abi,
    "executeCall",
    "Execute"
  );
  return { executeState, execute };
};

export const useCancel = () => {
  const globals = useGlobals();

  const { state: cancelState, send: cancel } = useContract(
    globals.GOVERNANCE_TIMELOCK,
    abi,
    "cancelCall",
    "Cancel"
  );
  return { cancelState, cancel };
};
