import { ReactNode } from "react";
import styled from "styled-components";
import { makeStyles, Tooltip } from "@material-ui/core";

import standardInfo from "../assets/ui/info.svg";
import highlightedInfo from "../assets/ui/highlighted-info.svg";

export interface TooltipItemType {
  label: string;
  value: string;
}

const Icon = styled.img`
  position: relative;
  cursor: pointer;

  margin-left: 0.8rem;
  margin-top: 1px;
  height: 1.2rem;
  @media (max-width: 600px) {
    margin-top: 2px;
    margin-left: 0.5rem;
    height: 0.9rem;
  }
`;

const Items = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1rem;
`;

const Item = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Value = styled.div`
  font-size: 1.3rem;
`;

const tooltipStyles = makeStyles(() => ({
  arrow: {
    color: "#433b6b",
  },
  tooltip: {
    backgroundColor: "#433b6b",
    fontSize: 13,
  },
}));

interface Props {
  children: ReactNode;
  items?: TooltipItemType[];
  info?: boolean;
}

const MeroTooltip = ({ children, items, info }: Props): JSX.Element => {
  return (
    <Tooltip
      arrow
      title={
        <>
          {children}
          {items && (
            <Items>
              {items.map((item: TooltipItemType) => (
                <Item key={item.label}>
                  <Value>{item.label}</Value>
                  <Value>{item.value}</Value>
                </Item>
              ))}
            </Items>
          )}
        </>
      }
      classes={tooltipStyles()}
    >
      <Icon src={info ? highlightedInfo : standardInfo} alt="help icon" />
    </Tooltip>
  );
};

export default MeroTooltip;
