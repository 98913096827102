import styled from "styled-components";
import { useSafeAppsSDK } from "@gnosis.pm/safe-apps-react-sdk";

import { shortenAddress, useEthers } from "@usedapp/core";

interface ConnectorProps {
  connected: boolean;
}

const StyledConnectorDesktop = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 715px) {
    display: none;
  }

  @media only percy {
    opacity: ${(props: ConnectorProps) => (props.connected ? 0 : 1)};
  }
`;

interface ButtonProps {
  connected: boolean;
}

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  height: ${(props: ButtonProps) => (props.connected ? "4.2rem" : "5.4rem")};
  width: ${(props: ButtonProps) => (props.connected ? "14rem" : "17.2rem")};
  border-radius: ${(props: ButtonProps) =>
    props.connected ? "8px" : "2.7rem"};

  transition: background-color 0.3s, background-position 0.5s;
  border: ${(props: ButtonProps) => (props.connected ? "1px" : "6px")} solid
    transparent;
  background: linear-gradient(
      ${(props: ButtonProps) => (props.connected ? "#0A0524" : "var(--main)")},
      ${(props: ButtonProps) => (props.connected ? "#0A0524" : "var(--main)")}
    ),
    ${(props: ButtonProps) =>
      props.connected
        ? "linear-gradient(to right, var(--primary) 0%, var(--secondary) 50%, var(--primary) 100%)"
        : "linear-gradient(to right, rgba(197, 50, 249, 0.7) 0%, rgba(50, 178, 229, 0.7) 50%, rgba(197, 50, 249, 0.7) 100%)"};
  background-origin: border-box;
  background-clip: padding-box, border-box;
  background-size: 200% auto;

  :hover {
    background-position: right center;

    div {
      background-position: right center;
    }
  }
`;

const ConnectorText = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 1.5rem;
  letter-spacing: 0.46px;
  background: linear-gradient(
    to right,
    var(--primary) 0%,
    var(--secondary) 50%,
    var(--primary) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: background-position 0.5s;
  background-size: 200% auto;
`;

const Connector = (): JSX.Element => {
  const { account, active, activateBrowserWallet } = useEthers();
  const { connected, safe } = useSafeAppsSDK();

  if (!account) {
    activateBrowserWallet();
  }

  return (
    <StyledConnectorDesktop connected={active}>
      <Button
        id="desktop-connector"
        onClick={() => activateBrowserWallet()}
        connected={active || connected}
      >
        <ConnectorText id="connector-address">
          {connected
            ? shortenAddress(safe.safeAddress)
            : account
            ? shortenAddress(account)
            : "Connect Wallet"}
        </ConnectorText>
      </Button>
    </StyledConnectorDesktop>
  );
};

export default Connector;
