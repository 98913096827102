import styled from "styled-components";

import CallSection from "../../components/CallSection";
import { useProposedCalls } from "../../contracts/views";

const StyledSafePage = styled.div`
  width: 100%;
  max-width: 120rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 3rem;
`;

const SafePage = () => {
  const proposedCalls = useProposedCalls();

  return (
    <StyledSafePage>
      <CallSection calls={proposedCalls} />
    </StyledSafePage>
  );
};

export default SafePage;
