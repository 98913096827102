import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import SafeProvider from "@gnosis.pm/safe-apps-react-sdk";

import { store } from "./state/store";
import GlobalStyles from "./styles/GlobalStyles";
import Routing from "./Routing";

ReactDOM.render(
  <React.StrictMode>
    <SafeProvider>
      <Provider store={store}>
        <GlobalStyles />
        <Routing />
      </Provider>
    </SafeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
