import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

import Connector from "./Connector";
import logo from "../assets/logo/mero-logo-white.svg";
import { useIsMultisigMember } from "../contracts/views";

interface NavItemType {
  label: string;
  link: string;
}

const navItems: NavItemType[] = [
  {
    label: "Calls",
    link: "/",
  },
  {
    label: "Create",
    link: "/create",
  },
  {
    label: "Proposed",
    link: "/safe",
  },
];

const StyledHeader = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 4rem;
`;

const LogoButton = styled.button`
  cursor: pointer;
`;

const Logo = styled.img`
  height: 4rem;
`;

const NavItems = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  transform: translate(-50%, -50%);
`;

const NavItem = styled(Link)`
  text-transform: capitalize;
  font-size: 1.6rem;
  white-space: nowrap;
  font-weight: 500;
  letter-spacing: 0.15px;
  margin: 0 3rem;

  transition: 0.3s opacity;
  :hover {
    opacity: 0.4;
  }

  @media (max-width: 600px) {
    font-size: 1.1rem;
  }
`;

const Header = () => {
  const navigate = useNavigate();
  const showHeaders = useIsMultisigMember();

  return (
    <StyledHeader>
      <LogoButton onClick={() => navigate("/")}>
        <Logo src={logo} alt="Mero logo" />
      </LogoButton>
      {showHeaders && (
        <NavItems>
          {navItems.map((navItem: NavItemType) => (
            <NavItem key={navItem.link} to={navItem.link}>
              {navItem.label}
            </NavItem>
          ))}
        </NavItems>
      )}
      <Connector />
    </StyledHeader>
  );
};

export default Header;
