import useContractAbi from "./use-contract-abi";

interface FunctionInput {
  name: string;
  type: string;
}

export interface FunctionType {
  constant: boolean;
  inputs: FunctionInput[];
  name: string;
}

const useContractFunctions = (contractAddress: string) => {
  const abi = useContractAbi(contractAddress);
  return abi
    .filter(
      (item: any) =>
        item.type === "function" &&
        (item.stateMutability === "nonpayable" ||
          item.stateMutability === "payable")
    )
    .map((item: any): FunctionType => {
      return {
        constant: item.constant,
        inputs: item.inputs,
        name: item.name,
      };
    });
};

export default useContractFunctions;
