import { useSafeAppsSDK } from "@gnosis.pm/safe-apps-react-sdk";
import { useContractCall, useEthers } from "@usedapp/core";
import { utils, BigNumber } from "ethers";
import { useEffect, useState } from "react";
import useGlobals from "../app/hooks/use-globals";
import { decodeDataInputs } from "../lib/decoder";

import abi from "./governance-timelock-abi.json";
import multisigAbi from "./mero-multisig.abi.json";

export enum CallStatus {
  Ready = "Ready",
  Pending = "Pending",
  Executed = "Executed",
  Cancelled = "Cancelled",
  Proposed = "Proposed",
}

export interface CallType {
  id: number;
  prepared: Date;
  target: string;
  selector: string;
  data: string;
  status: CallStatus;
}

interface Response {
  id: BigNumber;
  prepared: BigNumber;
  target: BigNumber;
  selector: BigNumber;
  data: BigNumber;
}

const formatResponse = (response: Response, status: CallStatus): CallType => {
  return {
    id: response.id.toNumber(),
    prepared: new Date(response.prepared.toNumber() * 1000),
    target: response.target.toString(),
    selector: response.selector.toString(),
    data: response.data.toString(),
    status,
  };
};

export const useOwner = () => {
  const globals = useGlobals();

  const [owner] = useContractCall({
    abi: new utils.Interface(abi),
    address: globals.GOVERNANCE_TIMELOCK,
    method: "owner",
    args: [],
  }) ?? [""];

  return owner;
};

export const useIsOwner = () => {
  const owner = useOwner();
  const { connected, safe } = useSafeAppsSDK();
  const { account } = useEthers();
  return (connected && safe.safeAddress === owner) || account === owner;
};

export const useIsMultisigMember = () => {
  const globals = useGlobals();
  const { account } = useEthers();

  const [owners] = useContractCall({
    abi: new utils.Interface(multisigAbi),
    address: globals.MERO_MULTISIG,
    method: "getOwners",
    args: [],
  }) ?? [[]];

  return account && owners.includes(account);
};

export const useProposedCalls = () => {
  const globals = useGlobals();
  const [calls, setCalls] = useState<CallType[]>([]);

  const lastExecutedURL = `${globals.GNOSIS_API_URL}safes/${globals.GNOSIS_SAFE}/multisig-transactions/?to=${globals.GOVERNANCE_TIMELOCK}&executed=true&limit=1`;
  const url = `${globals.GNOSIS_API_URL}safes/${globals.GNOSIS_SAFE}/multisig-transactions/?to=${globals.GOVERNANCE_TIMELOCK}&executed=false`;

  const fetchCalls = async () => {
    const lastExecutedResponse = await fetch(lastExecutedURL);
    const lastExecutedJson = await lastExecutedResponse.json();
    const lastExecutedNonce = lastExecutedJson.results[0]?.nonce || 0;
    const response = await fetch(`${url}&nonce__gt=${lastExecutedNonce}`);
    const json = await response.json();
    const data = json.results.map((result: any): CallType => {
      const selector = result.data.substring(0, 10);
      const params = decodeDataInputs(selector, result.data);

      return {
        id: result.nonce,
        prepared: new Date(result.submissionDate),
        target: params[0],
        selector: params[1].substring(0, 10),
        data: params[1],
        status: CallStatus.Proposed,
      };
    });
    setCalls(data);
  };

  useEffect(() => {
    fetchCalls();
  }, []);

  return calls;
};

export const usePendingCalls = () => {
  const globals = useGlobals();

  const [calls] = useContractCall({
    abi: new utils.Interface(abi),
    address: globals.GOVERNANCE_TIMELOCK,
    method: "notReadyCalls",
    args: [],
  }) ?? [[]];

  return calls.map((call: Response) =>
    formatResponse(call, CallStatus.Pending)
  );
};

export const useExecutedCalls = () => {
  const globals = useGlobals();

  const [calls] = useContractCall({
    abi: new utils.Interface(abi),
    address: globals.GOVERNANCE_TIMELOCK,
    method: "executedCalls",
    args: [],
  }) ?? [[]];

  return calls.map((call: Response) =>
    formatResponse(call, CallStatus.Executed)
  );
};

export const useCancelledCalls = () => {
  const globals = useGlobals();

  const [calls] = useContractCall({
    abi: new utils.Interface(abi),
    address: globals.GOVERNANCE_TIMELOCK,
    method: "cancelledCalls",
    args: [],
  }) ?? [[]];

  return calls.map((call: Response) =>
    formatResponse(call, CallStatus.Cancelled)
  );
};

export const useReadyCalls = () => {
  const globals = useGlobals();

  const [calls] = useContractCall({
    abi: new utils.Interface(abi),
    address: globals.GOVERNANCE_TIMELOCK,
    method: "readyCalls",
    args: [],
  }) ?? [[]];

  return calls.map((call: Response) => formatResponse(call, CallStatus.Ready));
};

export const useDelay = (id: number) => {
  const globals = useGlobals();

  const [delay] = useContractCall({
    abi: new utils.Interface(abi),
    address: globals.GOVERNANCE_TIMELOCK,
    method: "pendingCallDelay",
    args: [BigNumber.from(id)],
  }) ?? [BigNumber.from(0)];

  return delay.toNumber();
};
