import { shortenAddress } from "@usedapp/core";
import { utils } from "ethers";

import signatures from "./4byte_signatures.json";

export const decodeSelector = (signature: string) => {
  return (signatures as Record<string, string>)[signature.substring(2)];
};

export const decodeSelectorName = (signature: string) => {
  const selector = decodeSelector(signature);
  if (!selector) return "";
  return `${selector.substring(
    selector.indexOf(".") + 1,
    selector.indexOf("(")
  )}()`;
};

export const decodeSelectorContract = (signature: string) => {
  const selector = decodeSelector(signature);
  if (!selector) return "";
  return selector.substring(0, selector.indexOf("."));
};

export const decodeSelectorFunction = (signature: string) => {
  const selector = decodeSelector(signature);
  if (!selector) return "";
  return selector.substring(selector.indexOf(".") + 1);
};

export const decodeSelectorTypes = (signature: string): string[] => {
  const selector = decodeSelector(signature);
  if (!selector) return [];

  const types = [];
  let currentType = "";
  let inTuple = false;
  const lastIndex = selector.lastIndexOf(")");
  for (let index = selector.indexOf("(") + 1; index < lastIndex; index++) {
    const char = selector[index];

    if (!inTuple && char === ",") {
      types.push(currentType);
      currentType = "";
    } else {
      currentType += selector[index];
    }

    if (char === "(") {
      inTuple = true;
    } else if (char === ")") {
      inTuple = false;
    }
  }
  types.push(currentType);

  return types;
};

export const decodeDataInputs = (selector: string, data: string): any[] => {
  const types = decodeSelectorTypes(selector);
  return decodeDataInputsFromTypes(types, data);
};

export const decodeDataInputsFromTypes = (
  types: string[],
  data: string
): any[] => {
  if (types.length === 0) return [];
  const inputs: any[] = [];
  const results = utils.defaultAbiCoder.decode(
    types,
    utils.hexDataSlice(data, 4)
  );
  for (let i = 0; i < types.length; i++) {
    inputs.push(results[i]);
  }
  return inputs;
};
