import { useState } from "react";
import styled from "styled-components";
import dateformat from "dateformat";
import { shortenAddress } from "@usedapp/core";

import { CallType } from "../contracts/views";
import ExternalLink from "./ExternalLink";
import useGlobals from "../app/hooks/use-globals";
import { decodeSelectorContract, decodeSelectorName } from "../lib/decoder";
import CallDetails from "./CallDetails";
import chevron from "../assets/ui/chevron.svg";

const RowContainer = styled.div`
  width: 100%;
  position: relative;
  margin-top: 0.8rem;
`;

const StyledCall = styled.button`
  width: 100%;
  position: relative;
  height: 7.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--row-bg);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
  padding: 0 1.7rem;
  cursor: pointer;

  transition: background-color 0.3s;
  :hover {
    background-color: #1a1438;
  }

  > div:nth-child(1) {
    flex: 0.3;
  }
  > div:nth-child(2) {
    flex: 1.1;
  }
  > div:nth-child(5) {
    flex: 1.1;
  }
`;

const Data = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  letter-spacing: 0.15px;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2rem;
`;

const ChevronData = styled.div`
  width: 2.4rem;
`;

const Chevron = styled.img`
  height: 2.4rem;
  width: 2.4rem;
`;

interface Props {
  call: CallType;
}

const Call = ({ call }: Props) => {
  const globals = useGlobals();
  const [showDetails, setShowDetails] = useState(false);

  return (
    <>
      <RowContainer>
        <StyledCall onClick={() => setShowDetails(true)}>
          <Data>{call.id}</Data>
          <Data>{dateformat(call.prepared, "dS mmm yyyy - h:MM TT")}</Data>
          <Data>
            <ExternalLink
              large
              link={`${globals.ETHERSCAN_LINK}${call.target}`}
            >
              {shortenAddress(call.target)}
            </ExternalLink>
          </Data>
          <Data>{decodeSelectorContract(call.selector)}</Data>
          <Data>{decodeSelectorName(call.selector)}</Data>
          <ChevronData>
            <Chevron src={chevron} alt="right arrow" />
          </ChevronData>
        </StyledCall>
      </RowContainer>
      <CallDetails
        show={showDetails}
        close={() => setShowDetails(false)}
        call={call}
      />
    </>
  );
};

export default Call;
