import { useEffect, useState } from "react";
import { isAddress } from "@ethersproject/address";

import { ETHERSCAN_API_KEY } from "../globals";

const getContractAbi = async (contractAddress: string): Promise<any[]> => {
  const url = `https://api.etherscan.io/api?module=contract&action=getabi&address=${contractAddress}&apikey=${ETHERSCAN_API_KEY}`;
  const response = await fetch(url);
  const responseJson = await response.json();
  return JSON.parse((responseJson as any).result);
};

const useContractAbi = (contractAddress: string) => {
  const [abi, setAbi] = useState<any[]>([]);

  useEffect(() => {
    const getAbi = async () => {
      if (!contractAddress || !isAddress(contractAddress)) return;
      const abi = await getContractAbi(contractAddress);
      setAbi(abi);
    };
    getAbi();
  }, [contractAddress]);

  return abi;
};

export default useContractAbi;
