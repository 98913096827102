import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import CreatePage from "./pages/create-page/CreatePage";
import HomePage from "./pages/home/HomePage";
import SafePage from "./pages/safe/SafePage";

const Routing = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<HomePage />} />
          <Route path="create" element={<CreatePage />} />
          <Route path="safe" element={<SafePage />} />
          <Route path="*" element={<HomePage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Routing;
